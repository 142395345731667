import React from 'react'
import Hero from '../components/Hero'
import Info from '../components/Info'
import Top3 from '../components/Top3'
import LeadershipBoard from '../components/LeadershipBoard'
import Faq from '../components/Faq'
import Footer from '../components/Footer'

function Home() {
  return (
    <div>
        <Hero/>
        <Info/>
        <Top3/>
        <LeadershipBoard/>
        <Faq/>
        <Footer/>
    </div>
  )
}

export default Home