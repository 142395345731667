import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
// import Login from "./pages/Login";
// import Register from "./pages/Register";
import Signup from "./pages/Signup";
import Signin from "./pages/Signin";
import Agreement from "./pages/Agreement";
import Policy from "./pages/Policy";
import TermsConditions from "./pages/TermsConditions";
import ResetPassword from "./pages/ResetPassword";
import Rules from "./pages/Rules";

function App() {
  return (
    <div className="">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Signin />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/user-agreement" element={<Agreement />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/terms" element={<TermsConditions />} />
          <Route path="/rules" element={<Rules />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
